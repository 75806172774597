import React, { useEffect } from "react";
import { Quill } from "react-quill-new";

import ImageResize from "quill-image-resize-module-react";
import QuillTableBetter from "quill-table-better";

import { Container } from "./styles";
import "react-quill-new/dist/quill.snow.css";
import "quill-table-better/dist/quill-table-better.css";

Quill.register("modules/imageResize", ImageResize);
Quill.register({ "modules/table-better": QuillTableBetter }, true);

const QuillModules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
      [{ size: ["small", false, "large", "huge"] }],
      [{ color: [] }, { background: [] }],
      ["clean"],
      ["table-better"],
      [{ align: [] }],
    ],
  },
  table: false,
  "table-better": {
    language: "en_US",
    menus: [
      "column",
      "row",
      "merge",
      "table",
      "cell",
      "wrap",
      "copy",
      "delete",
    ],
    toolbarTable: true,
  },
  keyboard: {
    bindings: QuillTableBetter.keyboardBindings,
  },
  imageResize: {
    modules: ["Resize", "DisplaySize"],
  },
  clipboard: {
    matchVisual: false,
  },
};

const Editor = ({ answer, handleChange, handleKeyUp }) => {
  // console.log("answer", answer);
  useEffect(() => {
    // IMPORTANT: Force content update when component mounts
    // This is necessary for proper rendering of complex elements (tables, images)
    // The standard value prop mechanism may not handle HTML structure correctly,
    // so we directly set innerHTML to ensure proper rendering
    const editor = document.querySelector(".ql-editor");

    if (editor && answer) {
      editor.innerHTML = answer;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      id="answer"
      theme="snow"
      placeholder="Enter your Answer..."
      value={answer}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      modules={QuillModules}
      preserveWhitespace={true}
    />
  );
};

export default Editor;
